import { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'

import TextSeparator from 'components/TextSeparator/TextSeparator'
import Button from 'components/forms/Button/Button'
// import SignInGoogle from 'components/forms/Button/SignInGoogle/SignInGoogle'
import TextField from 'components/forms/TextField/TextField'

import './login.sass'
import { AuthContext } from 'context/AuthContext'

interface ChildMethods {
  focusInput: () => void;
  // Define additional methods if needed
}
const Login = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate();
  const [formInitialEmail, setInitialEmail] = useState("")
  const location = useLocation();
  const passwordRef = useRef<ChildMethods>(null);
  const queryParams: any = new URLSearchParams(location.search);
  const submitLogin = useCallback(
    async (
      values: { email: string; password: string },
      actions: FormikHelpers<{ email: string; password: string }>
    ) => {
      try {
        // Authenticate with the local email/password strategy
        const user = await client.authenticate({
          strategy: 'local',
          email: values.email,
          password: values.password,
        })
        if (user?.user?.permissions?.includes("temp") || user?.user?.permissions?.includes("customer")) {
          navigate('/customer')
        }

        if (user?.user?.permissions?.includes("user") || user?.user?.permissions?.includes("administrator")) {
          navigate('/jobs')
        }


      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false
        })
      }
      actions.setSubmitting(false)
    },
    [navigate]
  )

  useEffect(() => {
    if (queryParams.get("email") !== null) {
      setInitialEmail(queryParams.get("email"))
      if (passwordRef.current) {
        passwordRef.current.focusInput(); // Access the focusInput method
      }
    }
  }, [location])

  useEffect(() => {
    if (auth !== null && auth.authentication && auth.user) {
      if (auth?.user?.permissions?.includes("temp") || auth?.user?.permissions?.includes("customer")) {
        navigate('/customer')
      } else {
        navigate('/jobs')
      }
    }
  }, [auth])

  return (
    <div className="login-main">
      {/* <h3>Welcome back</h3> */}
      <h3>Login</h3>
      <p>Login with email and password </p>
      <Formik
        initialValues={{ email: formInitialEmail, password: '' }}
        enableReinitialize
        validate={(values) => {
          const errors: any = {}
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }

          if (!values.password) {
            errors.password = 'Required'
          }
          return errors
        }}
        onSubmit={submitLogin}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email address"
              id="email-input"
              placeholder="Type your email..."
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errors={errors}
              required
            />
            <div style={{ position: "relative" }}>
              <div className='forgot-pass'>
                <Link to="/login/recovery">Forgot password?</Link>
              </div>
              <TextField
                label="Password"
                id="password-input"
                placeholder="Type password..."
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                errors={errors}
                ref={passwordRef}
                required
              />

            </div>
            <Button
              type="submit"
              fullWidth
              disabled={isSubmitting}
              style={{
                marginTop: '20px',
              }}
            >
              Login
            </Button>
          </form>
        )}
      </Formik>


      {/* <Button 
        type="button"
        theme="secondary"
        fullWidth
        style={{
          marginTop: '24px',
        }}
      >
        <SignInGoogle />
      </Button> */}
      {/* <h5 className='' style={{ marginTop: '20px' }}>@Postal Apps </h5> */}

    </div>
  )
}
export default Login